<template>
    <div v-if="loading" class="tools-ghTgp pair-BdfTH">
        <div class="trade_preload">
            <img src="../../assets/img/loading_.svg" class="preload_img">
        </div>
    </div>
    <div v-else-if="loaded" class="tools-ghTgp pair-BdfTH">
        <div class="pair__top-BdfTH">
            <div class="pair__search-BdfTH">
                <input type="text" class="pair__search-input-BdfTH" :placeholder="$t('trading.assets.input')"
                    v-model="search">
                <span class="pair__search-icon-BdfTH">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" viewBox="0 0 171 171"
                        style=" fill:#8c90a5;">
                        <g transform="">
                            <g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                                stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                                font-family="none" font-size="none" style="mix-blend-mode: normal">
                                <path d="M0,171.98863v-171.98863h171.98863v171.98863z" fill="none"></path>
                                <g fill="#8c90a5">
                                    <path
                                        d="M71.82,10.26c-32.14265,0 -58.14,25.99735 -58.14,58.14c0,32.14266 25.99735,58.14 58.14,58.14c11.4757,0 22.09641,-3.36656 31.10063,-9.08437l42.00187,42.00188l14.535,-14.535l-41.4675,-41.36062c7.45453,-9.77906 11.97,-21.89602 11.97,-35.16187c0,-32.14265 -25.99734,-58.14 -58.14,-58.14zM71.82,23.94c24.62133,0 44.46,19.83867 44.46,44.46c0,24.62133 -19.83867,44.46 -44.46,44.46c-24.62133,0 -44.46,-19.83867 -44.46,-44.46c0,-24.62133 19.83867,-44.46 44.46,-44.46z">
                                    </path>
                                </g>
                                <path d="" fill=""></path>
                            </g>
                        </g>
                    </svg>
                </span>
            </div>
            <div class="pair__currency-BdfTH">
                <!-- {{ $t('trading.assets.name') }} <span>{{ assets.length }}</span> -->
            </div>
        </div>
        <div class="pair__sort-BdfTH">
            <div class="pair__sort-item-BdfTH pair__sort-item-BdfTH-pair">
                {{ $t('trading.assets.sort1') }}
            </div>
            <div class="pair__sort-item-BdfTH pair__sort-item-BdfTH-price">
                {{ $t('trading.assets.sort2') }}
            </div>
            <div class="pair__sort-container-BdfTH pair__sort-item-BdfTH-volume">
                <div class="pair__sort-item-BdfTH">{{ $t('trading.assets.sort3') }}</div>
                <span class="pair__sort-symbol-BdfTH">/</span>
                <div class="pair__sort-item-BdfTH pair__sort-select-BdfTH">{{ $t('trading.assets.sort4') }}</div>
                <div class="pair__sort-item-BdfTH">

                </div>
            </div>
        </div>
        <div class="pair__items-BdfTH">
            <div class="pair__items-container-BdfTH">
                <div v-for="item in filteredList" :key="item" @click="selectItem(item.symbol)">
                    <a class="pair__item-BdfTH" style="cursor: pointer"
                        :class="{ 'pair__item-selected-BdfTH': item.symbol == active }">
                        <div class="pair__item-name-BdfTH">
                            {{ item.symbol.includes("AT0000A2G9U5") ?  "Oil WTI" : item.symbol.includes("/") ? item.symbol.substring(0, item.symbol.indexOf("/"))
                                : item.symbol }}/USD
                        </div>
                        <div class="pair__item-price-BdfTH">
                            {{ item.close | numeralFormat('0,0.00') }}
                        </div>
                        <div class="pair__item-change-BdfTH"
                            :class="{ 'pair__item-plus-BdfTH': item.percent > 0, 'pair__item-minus-BdfTH': item.percent < 0 }">
                            {{ item.percent | numeralFormat('0.000') }}%
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ENUM from "../../config/enums"

export default {
    data() {
        return {
            search: '',
        }
    },
    computed: {
        active() {
            return this.$store.state.data.active.asset
        },
        assets() {
            return this.$store.state.data.assets
        },
        filteredList() {
            return this.assets.filter(item => {
                if(this.search.toLowerCase() === "wti") {
                    return item.symbol.toLowerCase().includes("at0000a2g9u5")
                }
                return item.symbol.toLowerCase().includes(this.search.toLowerCase())
            })
        },
        load() {
            return this.$store.state.basic.preload.trading
        },
        loaded() {
            return this.load === ENUM.LOADED
        },
        loading() {
            return this.load === ENUM.LOADING || this.load === ENUM.INIT
        },
    },
    methods: {
        selectItem(symbol) {
            this.$store.dispatch('data/change', symbol)
            setTimeout(() => {
                this.$socket.emit('getData', this.active)
                this.$socket.emit('updData', this.active)
            }, 1000)
        }
    }
}
</script>