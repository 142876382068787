<template>
    <div class="chart-ghTgp">
        <div v-if="show < 1" class="trade_preload">
            <img src="../../assets/img/loading_.svg" class="preload_img">
        </div>
        <div :style="{ opacity: show }" id="chart" style="height: 466px;"></div>
    </div>
</template>

<script>
import { dispose, init } from 'klinecharts'

export default {
    data() {
        return {
            options: {
                grid: {
                    show: true,
                    horizontal: {
                        show: true,
                        size: 1,
                        color: '#181d2b',
                    },
                    vertical: {
                        show: true,
                        size: 1,
                        color: '#181d2b',
                    }
                },
                candle: {
                    margin: {
                        top: 0.2,
                        bottom: 0.1
                    },
                    // 'candle_solid'|'candle_stroke'|'candle_up_stroke'|'candle_down_stroke'|'ohlc'|'area'
                    type: 'candle_solid',
                    bar: {
                        upColor: '#26A69A',
                        downColor: '#EF5350',
                        noChangeColor: '#888888'
                    },
                    area: {
                        lineSize: 2,
                        lineColor: '#2196F3',
                        value: 'close',
                        backgroundColor: [{
                            offset: 0,
                            color: 'rgba(33, 150, 243, 0.01)'
                        }, {
                            offset: 1,
                            color: 'rgba(33, 150, 243, 0.2)'
                        }]
                    },
                    priceMark: {
                        show: true,
                        high: {
                            show: true,
                            color: '#D9D9D9',
                            textMargin: 5,
                            textSize: 10,
                            textFamily: 'Helvetica Neue',
                            textWeight: 'normal'
                        },
                        low: {
                            show: true,
                            color: '#D9D9D9',
                            textMargin: 5,
                            textSize: 10,
                            textFamily: 'Helvetica Neue',
                            textWeight: 'normal',
                        },
                        last: {
                            show: true,
                            upColor: '#26A69A',
                            downColor: '#EF5350',
                            noChangeColor: '#888888',
                            line: {
                                show: true,
                                // 'solid'|'dash'
                                style: 'dash',
                                dashValue: [4, 4],
                                size: 1
                            },
                            text: {
                                show: true,
                                size: 12,
                                paddingLeft: 2,
                                paddingTop: 2,
                                paddingRight: 2,
                                paddingBottom: 2,
                                color: '#FFFFFF',
                                family: 'Helvetica Neue',
                                weight: 'normal',
                                borderRadius: 2
                            }
                        }
                    },
                    tooltip: {
                        // 'always' | 'follow_cross' | 'none'
                        showRule: 'always',
                        // 'standard' | 'rect'
                        showType: 'standard',
                        labels: ['时间', '开', '收', '高', '低', '成交量'],
                        values: null,
                        defaultValue: 'n/a',
                        rect: {
                            paddingLeft: 0,
                            paddingRight: 0,
                            paddingTop: 0,
                            paddingBottom: 6,
                            offsetLeft: 8,
                            offsetTop: 8,
                            offsetRight: 8,
                            borderRadius: 4,
                            borderSize: 1,
                            borderColor: '#3f4254',
                            backgroundColor: 'rgba(17, 17, 17, .3)'
                        },
                        text: {
                            size: 12,
                            family: 'Helvetica Neue',
                            weight: 'normal',
                            color: '#D9D9D9',
                            marginLeft: 8,
                            marginTop: 6,
                            marginRight: 8,
                            marginBottom: 0
                        }
                    }
                },
                separator: {
                    size: 1.25,
                    color: '#8c90a533',
                    fill: true,
                    activeBackgroundColor: 'rgba(230, 230, 230, .15)'
                },
                technicalIndicator: {
                    margin: {
                        top: 0.2,
                        bottom: 0.1
                    },
                    bar: {
                        upColor: '#26A69A',
                        downColor: '#EF5350',
                        noChangeColor: '#888888'
                    },
                    line: {
                        size: 1,
                        colors: ['#FF9600', '#9D65C9', '#2196F3', '#E11D74', '#01C5C4']
                    },
                    circle: {
                        upColor: '#26A69A',
                        downColor: '#EF5350',
                        noChangeColor: '#888888'
                    },
                    lastValueMark: {
                        show: false,
                        text: {
                            show: false,
                            color: '#ffffff',
                            size: 12,
                            family: 'Helvetica Neue',
                            weight: 'normal',
                            paddingLeft: 3,
                            paddingTop: 2,
                            paddingRight: 3,
                            paddingBottom: 2,
                            borderRadius: 2
                        }
                    },
                    tooltip: {
                        // 'always' | 'follow_cross' | 'none'
                        showRule: 'always',
                        // 'standard' | 'rect'
                        showType: 'standard',
                        showName: true,
                        showParams: true,
                        defaultValue: 'n/a',
                        text: {
                            size: 12,
                            family: 'Helvetica Neue',
                            weight: 'normal',
                            color: '#D9D9D9',
                            marginTop: 6,
                            marginRight: 8,
                            marginBottom: 0,
                            marginLeft: 8
                        }
                    }
                },
                xAxis: {
                    show: true,
                    height: null,
                    axisLine: {
                        show: true,
                        color: '#8c90a533',
                        size: 1
                    },
                    tickText: {
                        show: true,
                        color: '#8c90a5',
                        family: 'Helvetica Neue',
                        weight: 'normal',
                        size: 12,
                        paddingTop: 3,
                        paddingBottom: 6
                    },
                    tickLine: {
                        show: false,
                        size: 1,
                        length: 3,
                        color: 'rgba(140, 144, 165, 0.1)'
                    }
                },
                yAxis: {
                    show: true,
                    width: null,
                    // 'left' | 'right'
                    position: 'right',
                    // 'normal' | 'percentage' | 'log'
                    type: 'normal',
                    inside: false,
                    reverse: false,
                    axisLine: {
                        show: true,
                        color: '#8c90a533',
                        size: 1
                    },
                    tickText: {
                        show: true,
                        color: '#8c90a5',
                        family: 'Helvetica Neue',
                        weight: 'normal',
                        size: 12,
                        paddingLeft: 3,
                        paddingRight: 6
                    },
                    tickLine: {
                        show: false,
                        size: 1,
                        length: 3,
                        color: '#8c90a533'
                    }
                },
                crosshair: {
                    show: true,
                    horizontal: {
                        show: true,
                        line: {
                            show: true,
                            // 'solid'|'dash'
                            style: 'dash',
                            dashValue: [4, 2],
                            size: 1,
                            color: '#888888'
                        },
                        text: {
                            show: true,
                            color: '#D9D9D9',
                            size: 12,
                            family: 'Helvetica Neue',
                            weight: 'normal',
                            paddingLeft: 2,
                            paddingRight: 2,
                            paddingTop: 2,
                            paddingBottom: 2,
                            borderSize: 1,
                            borderColor: '#505050',
                            borderRadius: 2,
                            backgroundColor: '#505050'
                        }
                    },
                    vertical: {
                        show: true,
                        line: {
                            show: true,
                            // 'solid'|'dash'
                            style: 'dash',
                            dashValue: [4, 2],
                            size: 1,
                            color: '#888888'
                        },
                        text: {
                            show: true,
                            color: '#D9D9D9',
                            size: 12,
                            family: 'Helvetica Neue',
                            weight: 'normal',
                            paddingLeft: 2,
                            paddingRight: 2,
                            paddingTop: 2,
                            paddingBottom: 2,
                            borderSize: 1,
                            borderColor: '#505050',
                            borderRadius: 2,
                            backgroundColor: '#505050'
                        }
                    }
                },
            },
        }
    },
    computed: {
        chart() {
            return init("chart")
        },
        dataList() {
            return this.$store.state.data.ohlcv
        },
        symbol() {
            return this.$store.state.data.active.asset
        },
        show() {
            return this.$store.state.basic.params.chart
        }
    },
    sockets: {
        ohlcv(data) {
            this.chart.applyNewData(data)
            setTimeout(() => {
                this.$store.dispatch('basic/chart', 1)
            }, 500)
        },
        ohlcv_upd(data) {
            this.chart.updateData(data)
        }
    },
    mounted() {
        this.chart.createIndicator('MA', false, { id: 'candle_pane' })
        this.chart.createIndicator('VOL', false)
        this.chart.setStyles(this.options)
        this.$socket.emit('getData', this.symbol)
        setInterval(() => {
            this.$socket.emit('updData', this.symbol)
        }, 10000)
    },
    destroyed: function () {
        dispose('chart')
    }
}
</script>

<style>
.dads {
    color: #8c90a533;
}
</style>