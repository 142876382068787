<template>
    <div v-if="loading" class="info-ghTgp info">
        <div class="trade_preload">
            <img src="../../assets/img/loading_.svg" class="preload_img">
        </div>
    </div>
    <div v-else-if="loaded" class="info-ghTgp info">
        <div class="info__pair">{{ selected.includes("AT0000A2G9U5") ?  "Oil WTI" : selected.includes("/") ? selected.substring(0,
            selected.indexOf("/"))
            : selected }}/USD
        </div>
        <div class="info__price">
            <div class="info__price-title">
                {{ $t('trading.info.title1') }}
            </div>
            <div class="info__price-container">
                <div class="info__price-currency"
                    :class="{ 'info__price-minus': filtered.percent < 0, 'info__price-plus': filtered.percent >= 0 }">
                    {{ filtered.close | numeralFormat('$0,0.00') }}
                </div>
            </div>
        </div>
        <div class="info__change">
            <div class="info__change-title">
                {{ $t('trading.info.title2') }}
            </div>
            <div class="info__change-value"
                :class="{ 'info__change-minus': filtered.percent < 0, 'info__change-plus': filtered.percent >= 0 }">
                {{ filtered.percent | numeralFormat('0.000') }}%
            </div>
        </div>
        <div class="info__minmax">
            <div class="info__high">
                <div class="info__high-title">
                    {{ $t('trading.info.title3') }}
                </div>
                <div class="info__high-value">
                    {{ filtered.high | numeralFormat('$0,0.00') }}
                </div>
            </div>
            <div class="info__low">
                <div class="info__low-title">
                    {{ $t('trading.info.title4') }}
                </div>
                <div class="info__low-value">
                    {{ filtered.low | numeralFormat('$0,0.00') }}
                </div>
            </div>
        </div>
        <div class="info__volume">
            <div class="info__volume-title">
                {{ $t('trading.info.title5') }}
            </div>
            <div class="info__volume-container">
                <div class="info__volume-currency">
                    {{ filtered.volume | numeralFormat('$0,0.00') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ENUM from "../../config/enums"

export default {
    data() {
        return {
            pair: ['AAPL', 'USD'],
            lchlv: [100, 0.01, 100, 100, 100000000]
        }
    },
    computed: {
        selected() {
            return this.$store.state.data.active.asset
        },
        assets() {
            return this.$store.state.data.assets
        },
        filtered() {
            return this.assets.find(e => e.symbol === this.selected)
        },
        load() {
            return this.$store.state.basic.preload.trading
        },
        loaded() {
            return this.load === ENUM.LOADED
        },
        loading() {
            return this.load === ENUM.LOADING || this.load === ENUM.INIT
        }
    }
}
</script>