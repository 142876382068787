<template>
    <div v-if="loading" class="form-ghTgp">
        <div class="trade_preload">
            <img src="../../assets/img/loading_.svg" class="preload_img">
        </div>
    </div>
    <div v-else-if="loaded" class="form-ghTgp">
        <div class="button__container" id="btnBox">
            <div class="rushButton buttonActiveNewTrade">
                {{ $t('trading.order.title') }}
            </div>
        </div>
        <div class="form_content__container">
            <div class="form_leverage">
                <div class="form_content_index_labelm">{{ $t('trading.order.label1') }}</div>
                <div class="form_leverage_btns">
                    <div v-for="item in leveragesDefault" :key="item" @click="margin = item; calculate(1)"
                        :class="{ 'form_leverage_active': margin == item }" class="form_leverage_optbtn">{{
                            `${item}x`
                        }}</div>
                    <div class="form_leverage_selectbtn" @click="active.dropdown = !active.dropdown"
                        v-click-outside="() => active.dropdown = false">
                        <div class="form_leverage_dropdown">
                            <label style="cursor: pointer">
                                <div class="form_select" :class="{ 'form_leverage_active': margin > 5 }">
                                    {{ margin > 5 ? `${margin}x` : `${leveragesSelect[0]}x` }}
                                    <div class="form_dropdown_ico">
                                        <svg width="8.5" height="5" viewBox="0 0 7 4" fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg" class="iconindex___1AjOo">
                                            <path
                                                d="M6.051.163L3.504 2.708.957.163A.603.603 0 0 0 .567 0 .585.585 0 0 0 .17.148.522.522 0 0 0 0 .52V.53c0 .14.057.265.154.366l2.953 2.942A.585.585 0 0 0 3.504 4a.585.585 0 0 0 .398-.163L6.846.895A.535.535 0 0 0 7 .529a.497.497 0 0 0-.17-.381A.557.557 0 0 0 6.432 0a.6.6 0 0 0-.381.163z"
                                                fill="currentColor"></path>
                                        </svg>
                                    </div>
                                </div>
                            </label>
                            <div class="form_dropdown_index" v-show="active.dropdown">
                                <div>
                                    <div v-for="item in leveragesSelect" :key="item"
                                        @click="calculate(2, item); margin = item" class="form_select_btn">
                                        <span :class="{ active: margin == item }">{{ `${item}x` }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form_group_ind">
                <div class="form_content_index_label">{{ $t('trading.order.label2') }}</div>
                <div class="form_percent_btns">
                    <div class="form_percent_index">
                        <div class="percent_btn" @click="percent(25)">25%</div>
                        <div class="percent_btn" @click="percent(50)">50%</div>
                        <div class="percent_btn" @click="percent(75)">75%</div>
                        <div class="percent_btn" @click="percent(100)">{{ $t('trading.order.btn1') }}</div>
                    </div>
                </div>
            </div>
            <label class="rootindex___1uySA">
                <div class="labelindex___2H_89"></div>
                <div class="inputLineindex___33TUU">
                    <div class="inputBoxindex___1AOe8">
                        <input min="0" max="Infinity" precision="2" decimal="8" placeholder="0" class="inputindex___2PjuE"
                            v-model="params.amount" @input="calculate(0)">
                        <div class="suffixindex___1dksa">{{ symbol.includes("AT0000A2G9U5") ?  "Oil WTI" : symbol.includes("/") ? symbol.substring(0, symbol.indexOf("/"))
                            : symbol }}
                        </div>
                    </div>
                    <div class="controlsindex___PI2Wh">
                        <div class="controlItemindex___DeBkh">
                            <div class="iconindex___1AjOo">
                                <svg width="7" height="4" viewBox="0 0 7 4" fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg" class="rotate180index___EIv3y">
                                    <path
                                        d="M6.051.163L3.504 2.708.957.163A.603.603 0 0 0 .567 0 .585.585 0 0 0 .17.148.522.522 0 0 0 0 .52V.53c0 .14.057.265.154.366l2.953 2.942A.585.585 0 0 0 3.504 4a.585.585 0 0 0 .398-.163L6.846.895A.535.535 0 0 0 7 .529a.497.497 0 0 0-.17-.381A.557.557 0 0 0 6.432 0a.6.6 0 0 0-.381.163z"
                                        fill="currentColor"></path>
                                </svg>
                            </div>
                        </div>
                        <div class="controlItemindex___DeBkh">
                            <div class="iconindex___1AjOo">
                                <svg width="7" height="4" viewBox="0 0 7 4" fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg" class="iconindex___1AjOo">
                                    <path
                                        d="M6.051.163L3.504 2.708.957.163A.603.603 0 0 0 .567 0 .585.585 0 0 0 .17.148.522.522 0 0 0 0 .52V.53c0 .14.057.265.154.366l2.953 2.942A.585.585 0 0 0 3.504 4a.585.585 0 0 0 .398-.163L6.846.895A.535.535 0 0 0 7 .529a.497.497 0 0 0-.17-.381A.557.557 0 0 0 6.432 0a.6.6 0 0 0-.381.163z"
                                        fill="currentColor"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </label>
            <div class="form_group_ind">
                <div class="form_content_index_label">{{ $t('trading.order.label3') }}</div>
                <div class="controlItemindex___3YPKw">
                    <div style="font-size: 11px; line-height: normal;">
                        <div class="form_available_label">
                            {{ $t('trading.order.text1') }}&nbsp;
                            <div class="availableMarginindex___GeQK7" @click="available">{{ balance.available |
                                numeralFormat('0.[00]') }} USD
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <label class="rootindex___1uySA">
                <div class="labelindex___2H_89"></div>
                <div class="inputLineindex___33TUU">
                    <div class="inputBoxindex___1AOe8">
                        <input min="0" max="Infinity" precision="2" decimal="8" placeholder="0" class="inputindex___2PjuE"
                            v-model="params.value" @input="calculate(1)">
                        <div class="suffixindex___1dksa">{{ `USD` }}</div>
                    </div>
                    <div class="controlsindex___PI2Wh">
                        <div class="controlItemindex___DeBkh">
                            <div class="iconindex___1AjOo">
                                <svg width="7" height="4" viewBox="0 0 7 4" fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg" class="rotate180index___EIv3y">
                                    <path
                                        d="M6.051.163L3.504 2.708.957.163A.603.603 0 0 0 .567 0 .585.585 0 0 0 .17.148.522.522 0 0 0 0 .52V.53c0 .14.057.265.154.366l2.953 2.942A.585.585 0 0 0 3.504 4a.585.585 0 0 0 .398-.163L6.846.895A.535.535 0 0 0 7 .529a.497.497 0 0 0-.17-.381A.557.557 0 0 0 6.432 0a.6.6 0 0 0-.381.163z"
                                        fill="currentColor"></path>
                                </svg>
                            </div>
                        </div>
                        <div class="controlItemindex___DeBkh">
                            <div class="iconindex___1AjOo">
                                <svg width="7" height="4" viewBox="0 0 7 4" fill="currentColor"
                                    xmlns="http://www.w3.org/2000/svg" class="iconindex___1AjOo">
                                    <path
                                        d="M6.051.163L3.504 2.708.957.163A.603.603 0 0 0 .567 0 .585.585 0 0 0 .17.148.522.522 0 0 0 0 .52V.53c0 .14.057.265.154.366l2.953 2.942A.585.585 0 0 0 3.504 4a.585.585 0 0 0 .398-.163L6.846.895A.535.535 0 0 0 7 .529a.497.497 0 0 0-.17-.381A.557.557 0 0 0 6.432 0a.6.6 0 0 0-.381.163z"
                                        fill="currentColor"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </label>
            <div class="takeProfitStopLossContainerindex___pfRJ2">
                <div class="rootindex___2JeTg meindex___3thpW">
                    <div class="headerindex___3EFqi" @click="active.container1 = !active.container1">
                        <div class="titleindex___1txJR darkindex___3fPjR"
                            :class="{ 'activeindex___1gpcE': active.container1 }">Take Profit</div>
                        <div class="rootSwitcher___2Ygl5 switcherindex___20Q8Z"
                            :class="{ 'enabledindex___2nQZ8': active.container1 }">
                            <span class="indicatorSwitcher___1lq6T indicatorindex___2D27C"></span>
                        </div>
                    </div>
                    <div v-if="active.container1" class="bodyindex___3k8Ba">
                        <div class="rootindex___3ZBYo meindex___2VCcM">
                            <div class="headerindex___u1HSs">
                                <div class="titleindex___3Aspt  activeTabindex___2ypgA">{{ $t('trading.order.text3')
                                }}, USD</div>
                            </div>
                            <div class="contentindex___B0xmz">
                                <div class="rootindex___17MsN">
                                    <label class="label_stoploss_2H">
                                        <div class="labelindex___2H_89">
                                            <span data-index="0" class="labelindex___3WSeS">
                                                {{ $t('trading.order.text7') }}
                                            </span>
                                        </div>
                                        <div class="inputLineindex___33TUU">
                                            <div class="profitinput___Mt8Li_def">
                                                <input min="0" max="Infinity" precision="2" decimal="2" placeholder="0"
                                                    class="inputindex___2PjuE" v-model="params.tp.price"
                                                    @input="modificate(1)">
                                                <div class="suffixindex___1dksa">USD</div>
                                            </div>
                                        </div>
                                    </label>
                                    <div class="noticeindex___14iGR">
                                        <div><span class="titleindex___3Aspt ">{{ $t('trading.order.text2') }}</span>:
                                            <span class="noticeValueindex___29SvB takeProfitindex___2Orgf">{{
                                                params.tp.profit }}</span>
                                        </div>
                                        <div><span class="titleindex___3Aspt">{{ $t('trading.order.text4') }}</span>:
                                            <span class="noticeValueindex___29SvB ">{{ params.tp.change }}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rootindex___35nHE meindex___1VM4w">
                    <div class="headerindex___2hlQ8" @click="active.container2 = !active.container2">
                        <div class="titleindex___1txJR darkindex___3fPjR"
                            :class="{ 'activeindex___1gpcE': active.container2 }">Stop Loss</div>
                        <div class="rootSwitcher___2Ygl5 switcherindex___20Q8Z"
                            :class="{ 'enabledindex___2nQZ8': active.container2 }">
                            <span class="indicatorSwitcher___1lq6T indicatorindex___2D27C"></span>
                        </div>
                    </div>
                    <div v-if="active.container2" class="bodyindex___3k8Ba">
                        <div class="rootindex___3ZBYo meindex___2VCcM">
                            <div class="headerindex___u1HSs">
                                <div class="titleindex___3Aspt activeTabindex___2ypgA">{{ $t('trading.order.text3')
                                }}, USD</div>
                            </div>
                            <div class="contentindex___B0xmz">
                                <div class="rootindex___17MsN">
                                    <label class="label_stoploss_2H">
                                        <div class="labelindex___2H_89">
                                            <span data-index="0" class="labelindex___3WSeS">
                                                {{ $t('trading.order.text7') }}
                                            </span>
                                        </div>
                                        <div class="inputLineindex___33TUU">
                                            <div class="profitinput___Mt8Li_def">
                                                <input min="0" max="Infinity" precision="2" decimal="2" placeholder="0"
                                                    class="inputindex___2PjuE" v-model="params.sl.price"
                                                    @input="modificate(0)">
                                                <div class="suffixindex___1dksa">USD</div>
                                            </div>
                                        </div>
                                    </label>
                                    <div class="noticeindex___14iGR">
                                        <div><span class="titleindex___3Aspt ">{{ $t('trading.order.text5') }}</span>:
                                            <span class="noticeValueindex___29SvB lossindex___1K_Ww">{{ params.sl.profit
                                            }}</span>
                                        </div>
                                        <div><span class="titleindex___3Aspt">{{ $t('trading.order.text4') }}</span>:
                                            <span class="noticeValueindex___29SvB ">{{ params.sl.change }}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="totalindex___1I7bK">{{ $t('trading.order.text9') }} ≈ {{ params.total }} USD</div>
            <div v-if="filtered.status == false" class="rootindex___1hr9l">
                <div class="titleindex___saJSR">{{ $t('trading.order.text10') }}</div>
                <div class="timeindex___1ABYf">{{ $t('trading.order.text11') }}</div>
            </div>
            <div v-else-if="filtered.status == true" style="display: flex;">
                <button @click="buy" type="button" class=""
                    :class="{ 'isDisabledindex___3tEOL': !active.buysell, 'buttonindex___ReAXc': active.buysell }">
                    <div>
                        <div class="labelindex___8oA_z">{{ $t('trading.order.btn2') }}</div>
                        <div class="priceindex___3IeLj">{{ filtered.close | numeralFormat('0,0.00') }}</div>
                    </div>
                </button>
                <button @click="sell" class="buttonindexNegative_3Isma"
                    :class="{ 'isDisabledindex___3tEOL': active.buysell, 'buttonindex___ReAXc': !active.buysell }">
                    <div>
                        <div class="labelindex___8oA_z">{{ $t('trading.order.btn3') }}</div>
                        <div class="priceindex___3IeLj">{{ filtered.close | numeralFormat('0,0.00') }}</div>
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import ENUM from "../../config/enums"

export default {
    directives: {
        clickOutside: vClickOutside.directive
    },
    data() {
        return {
            pair: { t1: 'AAPL', t2: 'USD' },
            margins: [1, 2, 3, 4, 5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            margin: 20,
            closed: false,
            active: {
                buysell: true,
                dropdown: false,
                container1: false,
                container2: false,
            },
            params: {
                amount: null,
                value: null,
                total: 0,
                tp: {
                    profit: null,
                    price: null,
                    change: 15
                },
                sl: {
                    profit: null,
                    price: null,
                    change: 5
                }
            }
        }
    },
    sockets: {
        calculator(data) {
            if (data) {
                this.params.total = data.total
                return data.type == 0 ? this.params.value = data.value : this.params.amount = data.value
            }
        },
        modificator(data) {
            if (data) {
                const profit = Number(data.value.profit)
                const change = Number(data.value.change)
                return data.type ? this.params.tp = {
                    price: this.params.tp.price,
                    profit: profit,
                    change: change
                } : this.params.sl = {
                    price: this.params.sl.price,
                    profit: profit,
                    change: change
                }
            }
        },
        percent(data) {
            if (data) return this.params.amount = data
        },
        order(data) {
            if (data.code < 200) {
                this.params = {
                    amount: null,
                    value: null,
                    total: 0,
                    tp: {
                        profit: null,
                        price: null,
                        change: 15
                    },
                    sl: {
                        profit: null,
                        price: null,
                        change: 5
                    }
                }
                this.active.container1 = false
                this.active.container2 = false
            }
            return this.$toast.info(data.message)
        }
    },
    computed: {
        balance() {
            return this.$store.state.balance.balance
        },
        symbol() {
            return this.$store.state.data.active.asset
        },
        leveragesDefault() {
            if (this.margins.length > 5)
                return this.margins.slice(0, 5)
            else
                return this.margins.slice(0, this.margins.length)
        },
        leveragesSelect() {
            return this.margins.slice(5, this.margins.length)
        },
        assets() {
            return this.$store.state.data.assets
        },
        filtered() {
            return this.assets.find(e => e.symbol === this.symbol)
        },
        load() {
            return this.$store.state.basic.preload.trading
        },
        loaded() {
            return this.load === ENUM.LOADED
        },
        loading() {
            return this.load === ENUM.LOADING || this.load === ENUM.INIT
        }
    },
    methods: {
        calculate(type) {
            setTimeout(() => {
                this.$socket.emit('getCalculation', {
                    type: type,
                    symbol: this.symbol,
                    amount: this.params.amount,
                    value: this.params.value,
                    price: this.filtered.close,
                    margin: this.margin
                })
                this.modificate(1)
                this.modificate(0)
            }, 800)
        },
        modificate(type) {
            setTimeout(() => {
                this.$socket.emit('getModificator', {
                    type: type,
                    buysell: this.active.buysell,
                    symbol: this.symbol,
                    amount: this.params.amount,
                    value: this.params.value,
                    price: Number(this.filtered.close),
                    margin: this.margin,
                    tp: Number(this.params.tp.price),
                    sl: Number(this.params.sl.price)
                })
            }, 450)
        },
        percent(multiply) {
            setTimeout(() => {
                this.$socket.emit('getPercent', {
                    symbol: this.symbol,
                    value: (this.balance.available * this.margin) * (multiply / 100),
                    price: Number(this.filtered.close)
                })
                this.calculate(0)
            }, 450)
        },
        available() {
            this.params.value = this.balance.available
            this.calculate(1)
        },
        buy() {
            if (!this.active.buysell) {
                this.modificate(1)
                this.modificate(0)
                return this.active.buysell = true
            }
            setTimeout(() => {
                this.$socket.emit('openOrder', {
                    userid: JSON.parse(localStorage.getItem('token')),
                    symbol: this.symbol,
                    type: 0,
                    side: 0,
                    amount: Number(this.params.amount),
                    margin: this.margin,
                    tp: {
                        active: this.active.container1,
                        price: this.params.tp.price,
                        profit: this.params.tp.profit
                    },
                    sl: {
                        active: this.active.container2,
                        price: this.params.sl.price,
                        profit: this.params.sl.profit
                    },
                })
            }, 450)
        },
        sell() {
            if (this.active.buysell) {
                this.modificate(1)
                this.modificate(0)
                return this.active.buysell = false
            }
            setTimeout(() => {
                this.$socket.emit('openOrder', {
                    userid: JSON.parse(localStorage.getItem('token')),
                    symbol: this.symbol,
                    type: 0,
                    side: 1,
                    amount: Number(this.params.amount),
                    margin: this.margin,
                    tp: {
                        active: this.active.container1,
                        price: this.params.tp.price,
                        profit: this.params.tp.profit
                    },
                    sl: {
                        active: this.active.container2,
                        price: this.params.sl.price,
                        profit: this.params.sl.profit
                    },
                })
            }, 450)
        }
    }
}
</script>

<style scoped>
label {
    display: block !important;
    margin-bottom: 0;
}
</style>