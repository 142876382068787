<template>
    <fragment>
        <br>
        <main>
            <div class="trade__container-ghTgp">
                <div class="trade__box-ghTgp">
                    <Info />
                    <Chart />
                    <History />
                    <Assets />
                    <Order />
                    <br>
                    <br>
                </div>
            </div>
        </main>
    </fragment>
</template>

<script>
import Info from '../components/trading/Info.vue'
import Chart from '../components/trading/Chart.vue'
import History from '../components/trading/History.vue'
import Assets from '../components/trading/Assets.vue'
import Order from '../components/trading/Order.vue'
import CONFIG from '../config/settings'

export default {
    components: {
        Info,
        Chart,
        History,
        Assets,
        Order
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn
        }
    },
    created() {
        if (!this.loggedIn)
            window.location.replace(CONFIG.BASEURL + '/signin')
    }
}
</script>
