<template>
    <div class="order-ghTgp">
        <div class="order-fbGht">
            <div class="order__box-fbGht">
                <div class="orders-hgtFg">
                    <div class="button__container" id="orders">
                        <div class="rushButton" @click="active.opened = true, active.history = false"
                            :class="{ 'buttonActiveNewTrade': active.opened }">
                            {{ $t('trading.history.title1') }}
                        </div>
                        <div class="rushButton" @click="active.history = true, active.opened = false"
                            :class="{ 'buttonActiveNewTrade': active.history }">
                            {{ $t('trading.history.title2') }}
                        </div>
                    </div>
                    <div class="orders_content__container tabsBoxOne"
                        style="height: auto; overflow: auto; width: auto; display: grid;">
                        <div v-if="!orders.opened.length && active.opened" class="content__container-item tabsBoxOne"
                            :class="{ 'itemActiveNew': active.opened }">
                            <div class="orders_noHis" style="margin-top: 50px; margin-bottom: 50px;">{{
                                $t('trading.history.info1') }}</div>
                        </div>
                        <div v-else-if="!orders.history.length && active.history" class="content__container-item tabsBoxOne"
                            :class="{ 'itemActiveNew': active.history }" style="max-height: 273px;">
                            <div class="orders_noHis" style="margin-top: 50px; margin-bottom: 50px;">{{
                                $t('trading.history.info2') }}
                            </div>
                        </div>
                        <div v-else class="content__container-item tabsBoxOne itemActiveNew" id="ordersConTwo"
                            style="max-height: 273px; overflow-x: auto">
                            <table style="width: 1035px; font-size: 13px; margin-top: 9px;">
                                <tbody v-if="orders.opened.length && active.opened">
                                    <tr>
                                        <th style="width: 16.25%; padding-left: 20px;">{{ $t('trading.history.label1') }}
                                        </th>
                                        <th style="width: 12.25%;">{{ $t('trading.history.label2') }}</th>
                                        <th style="width: 7.25%;">{{ $t('trading.history.label3') }}</th>
                                        <th style="width: 10.25%;">{{ $t('trading.history.label4') }}</th>
                                        <th style="width: 10.25%;">{{ $t('trading.history.label5') }}</th>
                                        <th style="width: 11.25%;">{{ $t('trading.history.label6') }}</th>
                                        <th style="width: 9.25%;">{{ $t('trading.history.label8') }}</th>
                                        <th style="width: auto;">TP/SL</th>
                                        <th style="width: 9.25%;">{{ $t('trading.history.label9') }}</th>
                                    </tr>
                                    <tr v-for="item in orders.opened.slice().reverse()" :key="item" class="items_table">
                                        <td style="width: 16.25%; padding-left: 20px;">{{ format_date(item.time) }}</td>
                                        <td style="width: 12.25%; display: flex;">{{ item.symbol.includes("AT0000A2G9U5") ?
                                            "Oil WTI" : item.symbol }}
                                            <div class="margin-box">{{ item.values.margin }}x</div>
                                        </td>
                                        <td style="width: 7.25%;" :style="{ color: typeColor(item.side) }">{{ item.side == 0
                                            ?
                                            $t('trading.history.type1') : $t('trading.history.type2') }}</td>
                                        <td style="width: 10.25%;">{{ item.status == 1 ? $t('trading.history.status1') :
                                            $t('trading.history.status2') }}</td>
                                        <td style="width: 10.25%;">{{ item.value | numeralFormat('0.00') }} USD</td>
                                        <td style="width: 11.25%;">{{ item.values.entry | numeralFormat('0.00') }} USD</td>
                                        <td style="width: 9.25%;"
                                            :class="{ 'table-plus': item.values.profit > 0, 'table-minus': item.values.profit < 0 }">
                                            {{ item.values.profit > 0 ? '+' : item.values.profit == 0 ? ' ' : '-' }}{{
                                                Math.abs(item.values.profit) |
                                                numeralFormat('0.00') }} USD</td>
                                        <td style="width: auto;">{{
                                            `${!item.values.tp ? '--' : item.values.tp.toFixed(2)} / ${!item.values.sl ?
                                                '--' :
                                                item.values.sl.toFixed(2)}`
                                        }}</td>
                                        <td style="width: 9.25%;" class="action-btn" @click="close(item.id)">
                                            {{ $t('trading.history.btn') }}</td>
                                    </tr>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </tbody>
                                <tbody v-if="orders.history.length && active.history">
                                    <tr>
                                        <th style="width: 16.25%; padding-left: 20px;">{{ $t('trading.history.label1') }}
                                        </th>
                                        <th style="width: 12.25%;">{{ $t('trading.history.label2') }}</th>
                                        <th style="width: 7.25%;">{{ $t('trading.history.label3') }}</th>
                                        <th style="width: 10.25%;">{{ $t('trading.history.label4') }}</th>
                                        <th style="width: 10.25%;">{{ $t('trading.history.label5') }}</th>
                                        <th style="width: 10.25%;">{{ $t('trading.history.label6') }}</th>
                                        <th style="width: 10.25%;">{{ $t('trading.history.label7') }}</th>
                                        <th style="width: 9.25%;">{{ $t('trading.history.label8') }}</th>
                                    </tr>
                                    <tr v-for="item in orders.history.slice().reverse()" :key="item" class="items_table">
                                        <td style="width: 16.25%; padding-left: 20px;">{{ format_date(item.time) }}</td>
                                        <td style="width: 12.25%; display: flex">{{ item.symbol.includes("AT0000A2G9U5") ?
                                            "Oil WTI" : item.symbol }}
                                            <div class="margin-box">{{ item.values.margin }}x</div>
                                        </td>
                                        <td style="width: 7.25%;" :style="{ color: typeColor(item.side) }">{{ item.side == 0
                                            ?
                                            $t('trading.history.type1') : $t('trading.history.type2') }}</td>
                                        <td style="width: 10.25%;">{{ item.status == 1 ? $t('trading.history.status1') :
                                            $t('trading.history.status2') }}</td>
                                        <td style="width: 10.25%;">{{ item.value | numeralFormat('0.00') }} USD</td>
                                        <td style="width: 10.25%;">{{ item.values.entry | numeralFormat('0.00') }}</td>
                                        <td style="width: 10.25%;">{{ item.values.exit | numeralFormat('0.00') }}</td>
                                        <td style="width: 9.25%;"
                                            :class="{ 'table-plus': item.values.profit > 0, 'table-minus': item.values.profit < 0 }">
                                            {{ item.values.profit > 0 ? '+' : item.values.profit == 0 ? ' ' : '-' }}{{
                                                Math.abs(item.values.profit) |
                                                numeralFormat('0.00') }} USD</td>
                                    </tr>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    data() {
        return {
            active: {
                opened: true,
                history: false
            },
        }
    },
    computed: {
        orders() {
            return this.$store.state.balance.orders
        },
        symbol() {
            return this.$store.state.data.active.asset
        },
        assets() {
            return this.$store.state.data.assets
        },
        filtered() {
            return this.assets.find(e => e.symbol === this.symbol)
        }
    },
    methods: {
        close(id) {
            this.$confirm('Вы уверены, что хотите закрыть ордер?')
                .then(() => {
                    this.$socket.emit('closeOrder', {
                        userid: JSON.parse(localStorage.getItem('token')),
                        id: id,
                    })
                })
        },
        typeColor(value) {
            if (!value) return 'rgb(102 187 102)'
            else return 'rgb(255, 108, 108)'
        },
        format_date(value) {
            if (value) {
                return moment.unix(value).format('YYYY-MM-DD HH:mm:ss')
            }
        }
    },
    components: {
    }
}
</script>


<style scoped>
table td,
table th {
    border-top: 0px !important;
}

.table-plus {
    color: #6b6;
}

.table-minus {
    color: #ff6c6c;
}

.action-btn {
    color: #ffce46;
    cursor: pointer;
    transition: all .3s;
}

.action-btn:hover {
    /* color: #ff6c6c; */
    opacity: .80;
}

tr:hover {
    background: none !important;
}

th {
    color: #8c90a5;
}

td {
    cursor: default;
    color: #fff;
    padding-top: 15px;
    padding-bottom: 15px;
}

.items_table:hover {
    background: #2a2e39 !important;
}

.margin-box {
    margin-left: 13px;
    height: 18px;
    width: 27px;
    flex: 0 0 auto;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #486df4;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    border-radius: 3px;
    background-color: rgba(72, 101, 244, 0.15)
}
</style>